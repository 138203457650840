


























































import { Component, Vue, Prop } from 'vue-property-decorator'
import { Contact, Meeting } from '@tada-team/tdproto-ts'
import { uiStore } from '@/store'

@Component({
  components: {
    MeetingMetaMember: () => import('./Member.vue'),
    IconRepeat2: () => import('@tada/icons/dist/IconRepeat2.vue'),
  },
})
export default class MeetingMeta extends Vue {
  @Prop({
    required: true,
    type: Meeting,
  }) readonly meeting!: Meeting

  @Prop({
    required: true,
    type: Contact,
  }) readonly owner!: Contact

  private formatter = new Intl.DateTimeFormat(this.$i18n.locale, {
    month: 'long',
    day: '2-digit',
    year: 'numeric',
  })

  get showMembers () {
    return this.meeting.meetingMembers?.slice(0, 3)
  }

  get createdTittle () {
    return this.$t('meetings.fullMeeting.createdTitle', {
      date: this.formatter.format(new Date(this.meeting.created)),
    }).toString()
  }

  get deadlineTitle () {
    if (!this.meeting.deadline) return ''
    return this.$t('meetings.fullMeeting.deadlineTitle', {
      date: this.formatter.format(new Date(this.meeting.deadline)),
    }).toString()
  }

  get membersTitle () {
    const membersCount = this.meeting.meetingMembers?.length ?? 0
    return `${this.$t('meetings.fullMeeting.members').toString()} (${membersCount})`
  }

  private openDialog () {
    uiStore.actions.showModal({
      instance: 'all-members',
      payload: {
        jid: this.meeting.jid,
        edit: false,
      },
    })
  }
}
